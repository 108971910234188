class IndexComponent < LitElement
  self.styles = <<~CSS
    :root {
        --body-color: --sl-color-neutral-1000;
        --body-bg-color: --sl-color-neutral-0;
    }

    #card {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  CSS

  custom_element "index-component"

  def initialize
    @name = "Card"
  end

  def render
    <<~HTML
        <sl-divider>
            <sl-card class="card-basic" id="card">
                Are you seriously using Threads?
            </sl-card>
        </sl-divider>
    HTML
  end
end
